import { Gondor, Response } from "../Gondor";

const ACTIONS = {
  COUNTRY_CODES: "/country?order=description:asc",
};

export interface CountryCode {
  id: number;
  description: string;
  countryCode: string;
  phoneCode: string;
  createdAt: Date;
  updatedAt: Date;
}

export async function findCountryCodes() {
  const response = await Gondor.API.get<Response<CountryCode[]>>(
    ACTIONS.COUNTRY_CODES
  );
  return response.data.data;
}
